import React from 'react';
import { analytics } from './firebase';
import { logEvent } from 'firebase/analytics';

export const Para = (props) => <div className="mt-6">{props.children}</div>;

export const Navigation = (props) => (
  <p className="mt-16 mb-6 w-100 text-center">{props.children}</p>
);

export const FootnoteLink = (props) => (
  <a
    href={props.href}
    className="text-blue-400"
    target="_blank"
    rel="noreferrer"
  >
    {props.children}
  </a>
);

export const Footnote = (props) => {
  const [showFootnote, setShowFootnote] = React.useState(false);

  const onMouseEnter = () => {
    logEvent(analytics, `footnote_on_${props.id}`, {
      name: props.text,
    });
    setShowFootnote(true);
  };

  const onMouseLeave = () => {
    logEvent(analytics, `footnote_off_${props.id}`, {
      name: props.text,
    });
    setShowFootnote(false);
  };

  const wrapper = (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className="absolute clear-none left-0 w-screen p-0 flex flex-row content-center text-white bg-opacity-90 backdrop-filter backdrop-blur-lg bg-black shadow-2xl items-center justify-center"
    >
      <div className="max-w-sm sm:max-w-md xl:max-w-lg pt-2 pb-4 text-xl tracking-normal p-0 m-0 xl:pl-3 xl:pr-3">
        {props.children}
      </div>
    </div>
  );

  const hoverableAnchor = (
    <a
      href=""
      onClick={(e) => {
        e.preventDefault();
      }}
      className="bg-white text-blue-700 hover:bg-yellow-200 hover:text-black"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      ref={(el) => {
        // el can be null - see https://reactjs.org/docs/refs-and-the-dom.html#caveats-with-callback-refs
        if (!el) return;
      }}
    >
      {props.text}
    </a>
  );

  return (
    <div className="inline-block  hover:text-white">
      {hoverableAnchor}
      {showFootnote && wrapper}
    </div>
  );
};

export const FootnoteListElement = (props) => (
  <li className="flex flex-row w-full items-start border-pink-100 whitespace-nowrap md:whitespace-pre-wrap">
    {props.subtitle && (
      <div
        className="tracking-wider text-gray-400 text-md text-left"
        style={{ width: '60px' }}
      >
        {props.subtitle}
      </div>
    )}

    <div className="pl-2 text-left" style={{ width: '35px' }}>
      {props.emoji}
    </div>

    <div className="w-full pl-2">{props.children}</div>
  </li>
);

export const MainColumn = (props) => (
  // <div className="text-justify w-5/6 xl:w-1/2 text-xl">{props.children}</div>
  <div className="text-justify w-5/6 text-2xl max-w-lg leading-9 tracking-tight">
    {props.children}
  </div>
);
