import './App.css';
import React from 'react';
import EmojiMeditators from './EmojiMeditators/component';

import {
  Para,
  Navigation,
  Footnote,
  MainColumn,
  FootnoteListElement,
} from './utils';

function App() {
  return (
    <div className="w-full flex flex-row items-center justify-center">
      <MainColumn>
        <div className="w-full flex flex-row-reverse h-40 mt-10">
          <img src="/avatar.png" className="object-center" alt="[my avatar]" />
        </div>
        <div className="mb-5">Hi,</div>
        I’m{' '}
        <Footnote text="Zbigniew" id="name_pronounciation">
          Here's how my name is pronounced in my native Polish.{' '}
          <div className="w-full m-4">
            {' '}
            <table width="100%" className="border-pink-400">
              <tbody>
                <tr>
                  <td width="20%"></td>
                  <td width="60%">
                    {' '}
                    <audio controls>
                      <source src="/zbigniew.mp3" />

                      <p>
                        Your browser does not support HTML5 audio, but you can
                        still
                        <a href="https://upload.wikimedia.org/wikipedia/commons/7/74/Pl-Zbigniew.ogg">
                          download the music
                        </a>
                        .
                      </p>
                    </audio>
                  </td>
                  <td width="20%"></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="text-xs font-thin w-full text-center text-gray-300">
            ©{' '}
            <a href="https://commons.wikimedia.org/wiki/User:Equadus">
              User:Equadus
            </a>{' '}
            / <a href="https://commons.wikimedia.org/">Wikimedia Commons</a> /{' '}
            <a href="https://creativecommons.org/licenses/by-sa/3.0/">
              CC-BY-SA-3.0
            </a>
          </div>
        </Footnote>
        &nbsp;&nbsp;
        <EmojiMeditators className="" alt="waving hand" />
        <Para alt="tldr">
          I build software development organizations, and I launch digital
          products and companies from scratch.
        </Para>
        <Para alt="values">
          I understand business and care about creating productive yet enjoyable
          work cultures. I'm comfortable with risk; eager to get things done;
          easily excited by thoughtful design of any kind. I consider the right
          amount of process and solid product work paramount.
        </Para>
        <Para alt="business experience">
          I've co-founded{' '}
          <Footnote text="6 ventures" id="founded_ventures">
            <ul className="m-3 leading-loose text-left">
              <FootnoteListElement emoji="📞" subtitle="2006">
                VoIP network operator
              </FootnoteListElement>
              <FootnoteListElement emoji="✍🏼" subtitle="2007">
                Microblogging site
              </FootnoteListElement>
              <FootnoteListElement emoji="🖥" subtitle="2008">
                macOS and iOS product studio
              </FootnoteListElement>
              <FootnoteListElement emoji="👷‍♀️" subtitle="2016">
                Blue-collar recruitment startup
              </FootnoteListElement>
              <FootnoteListElement emoji="📦" subtitle="2017">
                Product development company
              </FootnoteListElement>
              <FootnoteListElement emoji="📹" subtitle="2020">
                macOS webcam plugin
              </FootnoteListElement>
            </ul>
          </Footnote>{' '}
          across New York City, Warsaw, and Singapore. I’ve been growing
          bootstrapped companies organically but have also built teams with{' '}
          <Footnote text="lightning speed" id="high_growth">
            <p>
              In 2021 I participated in building a 15-minute grocery delivery
              startup from the ground up. The business is a spin-out from a
              major convenience retail player in CEE (7,500+ brick-and-mortar
              stores).
            </p>
            <div className="mt-3">
              In less than five months, starting from scratch:
              <ul className="m-3 leading-loose">
                <FootnoteListElement emoji="🧑‍💻">
                  Hired 30+ senior developers.
                </FootnoteListElement>
                <FootnoteListElement emoji="🍏">
                  Shipped iOS App (Swift).
                </FootnoteListElement>
                <FootnoteListElement emoji="🤖">
                  Shipped Android App (Kotlin).
                </FootnoteListElement>
                <FootnoteListElement emoji="⚙️">
                  Released Platform (GraphQL, TS, GCP).
                </FootnoteListElement>
                <FootnoteListElement emoji="🧑‍🤝‍🧑">
                  Built auxiliary teams: Web, SRE, QA, Security/Privacy, IT.
                </FootnoteListElement>
              </ul>
              As a management board member, I was fully responsible for figuring
              out both the people/teams and the code side of technology. Most
              hires came from my personal network. Actively participated in
              building the product organization as well.
            </div>
          </Footnote>{' '}
          in high-growth startups. Managed seven-figure tech budgets but also
          gone through multiple near-death startup experiences while at the
          helm. Put design and product teams together and made them work well
          with developers. Introduced fundamental agile practices, ran product
          workshops, sold projects to F500 companies and startups all over the
          place, went through an acquisition/exit. Recruited more than 200
          people over the years. Had to part ways with the best and dearest
          teammates too. Materially participated in shipping close to 100 apps.
        </Para>
        <Para alt="INDUSTRIEZ DIZRUPTED">
          I’ve worked in e-commerce, telehealth, recruitment, social networks,
          instant messaging, telecommunications, consulting, and cybersecurity.
          Most of all, I enjoy working in industries I have no previous
          experience with.
        </Para>
        <Para alt="technical chops still there">
          I'm a hacker at heart.{' '}
          <Footnote text="Wrote" id="code">
            <p>
              Some tech buzzwords I have actual experience with, in no
              particular order: React, TypeScript, GraphQL, GCP, Node.js,
              POSIX-based operating systems, Objective-C, macOS, iOS, TCP/IP,
              VoIP, Swift, C, esp32, reverse-engineering, PostgreSQL, Ruby
              (+Rails), Asterisk PBX, Perl, PL/pgSQL, Python, AWS, Cocos2D,
              FreeBSD, computer security, ATMega, soldering iron, HIPAA, GDPR,
              MVNO, React Native, Slack bots, FB Bots, Q.931, SIP, RTP, telco
              billing systems.
            </p>
          </Footnote>{' '}
          a ton of terrible code and a few lines of good software since 1998.
          Even though I rarely commit production code these days anymore, not a
          single week passes without me tinkering with new ideas,
          reverse-engineering a piece of software, experimenting with new tech …
          toying with some new business concept, clever productivity trick, app
          prototype, low-code hack, wearable gizmo proof-of-concept, or
          practical tweak to my DIY off-grid shipping container cabin.
        </Para>
        <Para alt="fundamentally">
          Fundamentally, I try to learn how the world works, imagine how it
          could work instead, and figure out how to get there.
        </Para>
        <Para alt="career backing">
          Over the last 24+ years of my career, I’ve built a strong network of
          exceptionally talented and driven people who enjoy collaborating and
          are always keen to consider creating something new.
        </Para>
        <Para alt="career backing">
          Whether you're interested in launching the next big (or tiny) thing,
          wondering if there's a way to build it—or just want to exchange war
          stories—let me know. Always happy to chat and help if I can!
        </Para>
        <Navigation>
          <a
            href="mailto:zbigniew@sobiecki.name"
            target="_blank"
            className="text-blue-400 hover:underline"
            rel="noreferrer"
          >
            E-mail
          </a>{' '}
          |{' '}
          <a
            href="https://linkedin.com/in/zbigniewsobiecki"
            target="_blank"
            className="text-blue-400 hover:underline"
            rel="noreferrer"
          >
            LinkedIn
          </a>{' '}
          |{' '}
          <a
            href="https://twitter.com/zbigniew"
            className="text-blue-400 hover:underline"
            target="_blank"
            rel="noreferrer"
          >
            Twitter
          </a>{' '}
          |{' '}
          <a
            href="https://meet.zbigniew.me/"
            className="text-blue-400 hover:underline"
            target="_blank"
            rel="noreferrer"
          >
            Calendly
          </a>
        </Navigation>
      </MainColumn>
    </div>
  );
}

export default App;
