import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

let config = {
  apiKey: 'AIzaSyDEYn067_ttB_qGni2a-y5evkbuZSHHzUg',
  authDomain: 'zoom-rooms-experiment.firebaseapp.com',
  projectId: 'zoom-rooms-experiment',
  storageBucket: 'zoom-rooms-experiment.appspot.com',
  messagingSenderId: '969442871124',
  appId: '1:969442871124:web:d068994b06fa1f1466aac1',
  measurementId: 'G-DJ96EGP4V6',
};

export const app = initializeApp(config);
export const analytics = getAnalytics(app);
