import React, { Component } from 'react';
import styled from 'styled-components';

const emojiChangeDelay = 3.5;
const emojiChangeDuration = 3.5;

const EmojiMeditatorsContainer = styled.div`
  width: 32px;
  height: 32px;
  font-size: 24px;
  clear: left;
  position: absolute;
  display: inline;
`;

const EmojiMeditator = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: inline-block;
  top: 0;
  left: 0;
  transition: opacity ${emojiChangeDuration}s ease-in-out;
  opacity: ${(props) => props.opacity};
`;

class EmojiMeditators extends Component {
  constructor() {
    super();

    this.emojis = ['👋', '👋🏻', '👋🏼', '👋🏽', '👋🏾', '👋🏿'];

    this.state = {
      currentEmoji: null,
      nextEmoji: null,
      currentEmojiOpacity: 1,
      nextEmojiOpacity: 0,
    };
  }

  componentDidMount() {
    this.tick();
  }

  tick = () => {
    this.swapEmojis();
    setTimeout(this.tick, emojiChangeDelay * 1000);
  };

  randomOtherEmoji() {
    let emojiId;
    let emoji;

    do {
      emojiId = Math.floor(Math.random() * 100) % this.emojis.length;
      emoji = this.emojis[emojiId];
    } while (emoji === this.state.currentEmoji);

    return emoji;
  }

  swapEmojis() {
    if (this.state.currentEmojiOpacity === 0) {
      // current emoji is fully visible
      this.setState({
        currentEmoji: this.randomOtherEmoji(),
        currentEmojiOpacity: 1,
        nextEmojiOpacity: 0,
      });
    } else {
      // next emoji is fully visible
      this.setState({
        nextEmoji: this.randomOtherEmoji(),
        currentEmojiOpacity: 0,
        nextEmojiOpacity: 1,
      });
    }
  }

  render() {
    return (
      <EmojiMeditatorsContainer>
        <EmojiMeditator
          id="logo-image-current"
          opacity={this.state.currentEmojiOpacity}
        >
          {this.state.currentEmoji}
        </EmojiMeditator>
        <EmojiMeditator
          id="logo-image-next"
          opacity={this.state.nextEmojiOpacity}
        >
          {this.state.nextEmoji}
        </EmojiMeditator>
      </EmojiMeditatorsContainer>
    );
  }
}

export default EmojiMeditators;
